import algoliasearch from 'algoliasearch/lite';

export const algoliaConfig = {
    ALGOLIA_APP_ID: '69CDR4K6B2',
    ALGOLIA_SEARCH_ONLY_API_KEY: '66b985fe3ec2bfafd4f51eb3cc4691b4',
};

export const searchClient = algoliasearch(
    algoliaConfig.ALGOLIA_APP_ID,
    algoliaConfig.ALGOLIA_SEARCH_ONLY_API_KEY
);
